import { executeOnSingleOrMultiple, } from "tsparticles-engine";
import { RepulseBase } from "./RepulseBase";
import { RepulseDiv } from "./RepulseDiv";
export class Repulse extends RepulseBase {
    load(data) {
        super.load(data);
        if (!data) {
            return;
        }
        this.divs = executeOnSingleOrMultiple(data.divs, (div) => {
            const tmp = new RepulseDiv();
            tmp.load(div);
            return tmp;
        });
    }
}
