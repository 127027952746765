import { Circle, ExternalInteractorBase, Rectangle, Vector, clamp, divMode, divModeExecute, getDistances, getEasing, isDivModeEnabled, isInArray, mouseMoveEvent, } from "tsparticles-engine";
import { Repulse } from "./Options/Classes/Repulse";
export class Repulser extends ExternalInteractorBase {
    constructor(engine, container) {
        super(container);
        this._clickRepulse = () => {
            const container = this.container, repulseOptions = container.actualOptions.interactivity.modes.repulse;
            if (!repulseOptions) {
                return;
            }
            const repulse = container.repulse || { particles: [] };
            if (!repulse.finish) {
                if (!repulse.count) {
                    repulse.count = 0;
                }
                repulse.count++;
                if (repulse.count === container.particles.count) {
                    repulse.finish = true;
                }
            }
            if (repulse.clicking) {
                const repulseDistance = container.retina.repulseModeDistance;
                if (!repulseDistance || repulseDistance < 0) {
                    return;
                }
                const repulseRadius = Math.pow(repulseDistance / 6, 3), mouseClickPos = container.interactivity.mouse.clickPosition;
                if (mouseClickPos === undefined) {
                    return;
                }
                const range = new Circle(mouseClickPos.x, mouseClickPos.y, repulseRadius), query = container.particles.quadTree.query(range, (p) => this.isEnabled(p));
                for (const particle of query) {
                    const { dx, dy, distance } = getDistances(mouseClickPos, particle.position), d = distance ** 2, velocity = repulseOptions.speed, force = (-repulseRadius * velocity) / d;
                    if (d <= repulseRadius) {
                        repulse.particles.push(particle);
                        const vect = Vector.create(dx, dy);
                        vect.length = force;
                        particle.velocity.setTo(vect);
                    }
                }
            }
            else if (repulse.clicking === false) {
                for (const particle of repulse.particles) {
                    particle.velocity.setTo(particle.initialVelocity);
                }
                repulse.particles = [];
            }
        };
        this._hoverRepulse = () => {
            const container = this.container, mousePos = container.interactivity.mouse.position, repulseRadius = container.retina.repulseModeDistance;
            if (!repulseRadius || repulseRadius < 0 || !mousePos) {
                return;
            }
            this._processRepulse(mousePos, repulseRadius, new Circle(mousePos.x, mousePos.y, repulseRadius));
        };
        this._processRepulse = (position, repulseRadius, area, divRepulse) => {
            const container = this.container, query = container.particles.quadTree.query(area, (p) => this.isEnabled(p)), repulseOptions = container.actualOptions.interactivity.modes.repulse;
            if (!repulseOptions) {
                return;
            }
            for (const particle of query) {
                const { dx, dy, distance } = getDistances(particle.position, position), velocity = (divRepulse?.speed ?? repulseOptions.speed) * repulseOptions.factor, repulseFactor = clamp(getEasing(repulseOptions.easing)(1 - distance / repulseRadius) * velocity, 0, repulseOptions.maxSpeed), normVec = Vector.create(distance === 0 ? velocity : (dx / distance) * repulseFactor, distance === 0 ? velocity : (dy / distance) * repulseFactor);
                particle.position.addTo(normVec);
            }
        };
        this._singleSelectorRepulse = (selector, div) => {
            const container = this.container, repulse = container.actualOptions.interactivity.modes.repulse;
            if (!repulse) {
                return;
            }
            const query = document.querySelectorAll(selector);
            if (!query.length) {
                return;
            }
            query.forEach((item) => {
                const elem = item, pxRatio = container.retina.pixelRatio, pos = {
                    x: (elem.offsetLeft + elem.offsetWidth / 2) * pxRatio,
                    y: (elem.offsetTop + elem.offsetHeight / 2) * pxRatio,
                }, repulseRadius = (elem.offsetWidth / 2) * pxRatio, area = div.type === "circle"
                    ? new Circle(pos.x, pos.y, repulseRadius)
                    : new Rectangle(elem.offsetLeft * pxRatio, elem.offsetTop * pxRatio, elem.offsetWidth * pxRatio, elem.offsetHeight * pxRatio), divs = repulse.divs, divRepulse = divMode(divs, elem);
                this._processRepulse(pos, repulseRadius, area, divRepulse);
            });
        };
        this._engine = engine;
        if (!container.repulse) {
            container.repulse = { particles: [] };
        }
        this.handleClickMode = (mode) => {
            const options = this.container.actualOptions, repulseOpts = options.interactivity.modes.repulse;
            if (!repulseOpts || mode !== "repulse") {
                return;
            }
            if (!container.repulse) {
                container.repulse = { particles: [] };
            }
            const repulse = container.repulse;
            repulse.clicking = true;
            repulse.count = 0;
            for (const particle of container.repulse.particles) {
                if (!this.isEnabled(particle)) {
                    continue;
                }
                particle.velocity.setTo(particle.initialVelocity);
            }
            repulse.particles = [];
            repulse.finish = false;
            setTimeout(() => {
                if (container.destroyed) {
                    return;
                }
                repulse.clicking = false;
            }, repulseOpts.duration * 1000);
        };
    }
    clear() {
    }
    init() {
        const container = this.container, repulse = container.actualOptions.interactivity.modes.repulse;
        if (!repulse) {
            return;
        }
        container.retina.repulseModeDistance = repulse.distance * container.retina.pixelRatio;
    }
    async interact() {
        const container = this.container, options = container.actualOptions, mouseMoveStatus = container.interactivity.status === mouseMoveEvent, events = options.interactivity.events, hover = events.onHover, hoverEnabled = hover.enable, hoverMode = hover.mode, click = events.onClick, clickEnabled = click.enable, clickMode = click.mode, divs = events.onDiv;
        if (mouseMoveStatus && hoverEnabled && isInArray("repulse", hoverMode)) {
            this._hoverRepulse();
        }
        else if (clickEnabled && isInArray("repulse", clickMode)) {
            this._clickRepulse();
        }
        else {
            divModeExecute("repulse", divs, (selector, div) => this._singleSelectorRepulse(selector, div));
        }
    }
    isEnabled(particle) {
        const container = this.container, options = container.actualOptions, mouse = container.interactivity.mouse, events = (particle?.interactivity ?? options.interactivity).events, divs = events.onDiv, hover = events.onHover, click = events.onClick, divRepulse = isDivModeEnabled("repulse", divs);
        if (!(divRepulse || (hover.enable && mouse.position) || (click.enable && mouse.clickPosition))) {
            return false;
        }
        const hoverMode = hover.mode, clickMode = click.mode;
        return isInArray("repulse", hoverMode) || isInArray("repulse", clickMode) || divRepulse;
    }
    loadModeOptions(options, ...sources) {
        if (!options.repulse) {
            options.repulse = new Repulse();
        }
        for (const source of sources) {
            options.repulse.load(source?.repulse);
        }
    }
    reset() {
    }
}
